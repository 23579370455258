import { Col } from 'antd';
import styles from './contentContainer.module.css';

const ContentContainer = ({
  children,
}: React.PropsWithChildren<{ children: JSX.Element }>): JSX.Element => {
  return (
    <>
      <Col className={styles.publicContent} span={24}>
        {children}
      </Col>
    </>
  );
};
export default ContentContainer;
